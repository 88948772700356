table {
  align-content: center;
  border: 1px solid #ddd;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
table th {
  background-color: #284479;
  color: #fff;
  padding: 10px;

}
table tr:nth-child(even) {background-color: #f2f2f2;}
table tr:hover {
  background-color: #e5ebf6;

}

table td, table th {
  border: 1px solid #ddd;
  font-size: 14px;
  padding: 10px;
  text-align: left;
}