.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container { 
  width: 1000;
  margin: '0 auto';
  display: 'flex';
  flex-direction: 'column';
  justify-content: 'center';
  padding: 20 }

.appRadio {
  border-collapse: collapse;
  border-spacing: 0;
  border: none;
  margin-bottom: 10;
  margin-left: 50px;
  color: #1d3158;
  background-color: #1c4936;
}



.appLabel {
  border-collapse: collapse;
  border-spacing: 0;
  width: 10%;
  border: none;
  margin-bottom: 10;
  font-family:serif;
  font-size: medium;
}

/* .appSMSLabel {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: medium;
  width: 10%;
  border: none;
  margin-bottom: 10;
  margin-left: 40px;
  font-family:serif;
} */

.tableLabel {
  border-collapse: collapse;
  border-spacing: 0;
  margin-left: 35px;
  font-size: medium;
  width: 10%;
  border: none;
  margin-bottom: 10;
  font-weight: bold;
  color: #1d3158;
}

.appButtonClickLabel {
  margin-left: 35px;
  font-size: medium;
  border: none;
  margin-bottom: 10;
  font-weight: bold;
  color: #196833;
  font-family:serif;
  font-size: medium;
}


.appH1 {
  margin-left: 40%;
  margin-bottom: 6;
  font-size: 20px;
}

.appSMSOpt {
  margin-bottom: 10;
  margin-left: 40px;
  border-spacing: 0;
  font-size: medium;
  color: #2415aa;
  font-family:serif;
  font-size: medium;
}

.appTable {
  width: 100%; 
  display: flex;
  align-items: center;
  justify-content: center;
}


.appInput { 
  border: 'none';
  background-color: '#ddd';
  margin-bottom: 5;
  padding: 8;
  font-size: medium;
 }

 .center {
  display: flex;
  align-items: center;
  justify-content: center;
 }

 .marginLeft{
  margin-left: auto;
  margin-right: auto;
  border-left: 100px #1d3158;
  border-right: 100px #1d3158;
  background-color: #e4eef0;
  width: 70%
}

.body{
  font-family: "Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
  font-size: 1em;
  }

@media screen and (min-width: 768px) {
		.container {
      padding: 25px;
      border: 1px solid #ddd;
      background-color: #fff;
      position: absolute;
        top: 2rem;
        left: 50vw; /* move to the middle of the screen (assumes relative parent is the body/viewport) */
        transform: translateX(
          -50%
        ); /* move backwards 50% of this element's width */
        min-width: calc(768px - (15px * 2)); /* == breakpoint - left+right margin */
        min-height: auto;
        box-shadow: 0 19px 38px rgb(0 0 0 / 12%), 0 15px 12px rgb(0 0 0 / 22%);
		}
}

.center {
  text-align: center;
}

h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h2, .h2 {
  font-size: 1.5em;
  margin-left: 0.67em;
  text-align:left !important;
  font-weight: bold;
  margin-bottom:1rem;
  }

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
}

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}
.card-header {
  padding: .0rem 0rem 0rem .75rem;
  margin: 0;
  background-color: rgba(0,0,0,.03);
  border-bottom: 1px solid rgba(0,0,0,.125);
}

.label_text {
box-sizing: border-box;
padding: 0.5em;
display: inline-block;
width: 30%;
text-align: right;
}

.label_info {
box-sizing: border-box;
padding-right: 0.5em;
width: 68%;
display: inline-block;
}

.bold {
  font-weight: bold;
}


.appButton {
  background-color: #e03c31;
color: white;
border: 2px solid #e03c31;
  border-radius: 8px;
padding: 5px 15px;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 16px;
margin: 4px 2px;
transition-duration: 0.4s;
cursor: pointer;
}
.appButton:hover {
  background-color: white;
color: black;
  }

 .appTable {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.nav{
  padding:30px;
}

/* table {
  align-content: center;
  border: 1px solid #ddd;
  border-collapse: collapse;
  border-spacing: 0;

}
table th {
  background-color: #284479;
  color: #fff;
  padding: 10px;

}
table tr:nth-child(even) {background-color: #f2f2f2;}
table tr:hover {
  background-color: #e5ebf6;

}

table td, table th {
  border: 1px solid #ddd;
  font-size: 14px;
  padding: 10px;
  text-align: left;
} */
.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}
.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}